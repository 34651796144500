$(document).ready(function() {

    new StepSlider().initialise();

});

function StepSlider() {

    var slidersClass = 'step-slider';
    var stepIndexClass = 'step-index';
    var slideIndexClass = 'slide-index';
    var stepDescriptionClass = 'step-title';
    var descriptionClass = 'description';
    var activeClass = 'active';
    var stepClass = 'step';
    var stepsClass = 'steps';
    var slideClass = 'slide';
    var slidesClass = 'slides';
    var forwardClass = 'forward';
    var backwardClass = 'backward';

    this.initialise = function() {

        $('.' + slidersClass).each(function() {

            var slider = $(this);

            buildSlider(slider);

            sliderBehaviour(slider);

            setActiveStep(slider);

        });

    };

    var buildSlider = function(slider) {

        addSteps(slider);

        addNavigation(slider);

        addIndex(slider);

        addDescription(slider);

    };

    var addNavigation = function(slider) {

        var slides = slider.find('.' + slidesClass);

        slides.append(
            '<span class="' + backwardClass +'"></span>' +
            '<span class="' + forwardClass +'"</span>'
        );

    };

    var addSteps = function(slider) {

        slider.prepend(
            '<div class="' + stepsClass + '"></div>'
        );

        createSteps(slider);

    };

    var createSteps = function(slider) {

        var slides = slider.find('.' + slideClass);
        var steps = slider.find('.' + stepsClass);

        slides.each(function() {

            steps.append('<div class="' + stepClass + '"></div>');

        });

    };

    var addIndex = function(slider) {

        var steps = slider.find('.' + stepClass);
        var slides = slider.find('.' + slideClass);

        createIndex(steps, stepIndexClass);
        createIndex(slides, slideIndexClass);

    };

    var createIndex = function(elements, name) {

        var index = 1;

        elements.each(function() {

            var element = $(this);

            element.attr('data-' + name, index);

            index++;

        });

    };

    var addDescription = function(slider) {

        var slides = slider.find('.' + slideClass);
        var noDescriptionsDefined = !slides.attr('data-' + stepDescriptionClass);

        if (noDescriptionsDefined) {
            return;
        }

        slides.each(function() {

            var slide = $(this);
            var slideIndex = slide.data(slideIndexClass);
            var step = getStepWithIndex(slider, slideIndex);
            var slideDescription = slide.data(stepDescriptionClass);

            step.append('<span class="' + descriptionClass + '">' + slideDescription + '</span>');

        });

    };

    var sliderBehaviour = function(slider) {

        selectSlide(slider);

        toggleForward(slider);

        toggleBackward(slider);

    };

    var selectSlide = function(slider) {

        var steps = slider.find('.' + stepClass);

        steps.click(function() {

            var currentStep = $(this);
            var currentStepIndex = currentStep.data(stepIndexClass);

            setActiveSet(slider, currentStepIndex);

        });

    };

    var toggleForward = function(slider) {

        var forward = slider.find('.' + forwardClass);

        forward.click(function() {

            var activeStep = slider.find('.' + stepClass + '.' + activeClass);

            if (activeStep.is(':last-child')) {

                var firstStep = slider.find('.' + stepClass + ':first-child');
                var firstStepIndex = firstStep.data(stepIndexClass);

                setActiveSet(slider, firstStepIndex);

                return;

            }

            var nextStep = activeStep.next('.' + stepClass);
            var nextStepIndex = nextStep.data(stepIndexClass);

            setActiveSet(slider, nextStepIndex);

        });

    };

    var toggleBackward = function(slider) {

        var backward = slider.find('.' + backwardClass);

        backward.click(function() {

            var activeStep = slider.find('.' + stepClass + '.' + activeClass);

            if (activeStep.is(':first-child')) {

                var lastStep = slider.find('.' + stepClass + ':last-child');
                var lastStepIndex = lastStep.data(stepIndexClass);

                setActiveSet(slider, lastStepIndex);

                return;

            }

            var previousStep = activeStep.prev('.' + stepClass);
            var previousStepIndex = previousStep.data(stepIndexClass);

            setActiveSet(slider, previousStepIndex);

        });

    };

    var setActiveStep = function(slider) {

        var activeSlide = slider.find('.' + slideClass + '.' + activeClass);

        if (activeSlide.length) {

            var activeSlideIndex = activeSlide.data(slideIndexClass);

            setActiveSet(slider, activeSlideIndex);

            return;

        }

        setActiveSet(slider, 1);

    };

    var getSlideWithIndex = function(slider, index) {

        return slider.find('.' + slideClass + '[data-' + slideIndexClass +'="' + index + '"]');

    };

    var getStepWithIndex = function(slider, index) {

        return slider.find('.' + stepClass + '[data-' + stepIndexClass + '="' + index + '"]');

    };

    var setActiveSet = function(slider, index) {

        var steps = slider.find('.' + stepClass);
        var slides = slider.find('.' + slideClass);
        var activeStep = getStepWithIndex(slider, index);
        var activeSlide = getSlideWithIndex(slider, index);

        setActive(activeStep, steps);
        setActive(activeSlide, slides);

    };

    var setActive = function(current, all) {

        all.removeClass(activeClass);
        current.addClass(activeClass);

    };

}
